import React from "react";

export const Header = (props) => {
  
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <header id="header">
      <div className="intro img2">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col -md-8 col-md-offset-2 col-xs-12 intro-text">
                <div>
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <h1 className="upp color-header">
                  {props.data ? props.data.title2 : "Loading"}
                  <span></span>
                </h1>
                <h1>
                  {props.data ? props.data.title3 : "Loading"}
                  <span></span>
                </h1>
                <h1 className="upp">
                  {props.data ? props.data.title4 : "Loading"}
                  <span></span>
                </h1>
                </div>
                <div className="m-top m-bottom">
                <p>
                  {props.data ? props.data.sub1 : "Loading"}
                  <span></span>
                </p>
                <p>
                  {props.data ? props.data.sub2 : "Loading"}
                  <span></span>
                </p>
                </div>
                <div className="pt-2"> 
                  <button className="btn btn-custom btn-lg page-scroll" onClick={() => openInNewTab("https://app.arcobank.com.br/register")}>
                  Abra sua conta
                  </button>
                </div>
              
                {" "}
              </div>
              <div className="col-md-6 col-xs-12 col-md-offset-2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
